import { baseUrl } from '@tricks-gmbh/nuxt-tools/src/layers/base/helpers/gatherEnvVariables'

const organizationName = 'Smartsquare'
const logoUrl = `${baseUrl}/logo.png`

export default defineAppConfig({
  site: {
    name: organizationName,
  },
  // TODO: do we need that? ... or just use schemaOrg.organization.name in seoTitleAndDescription?
  meta: {
    name: organizationName,
    ogImage: '/img/home/home-breaker.png',
  },
  schemaOrg: {
    organization: {
      // use https://schema.org/LocalBusiness respectively a subtype https://schema.org/LocalBusiness#subtypes when applicable
      // '@type': 'LocalBusiness',

      description:
        'Wir sind Softwareentwickler und erstellen für Sie individuelle IoT-Plattformen, Web-Anwendungen und Apps.',

      name: organizationName,

      logo: logoUrl,
      image: logoUrl,

      address: {
        '@type': 'PostalAddress',
        streetAddress: 'Otto-Brenner-Str. 247',
        addressLocality: 'Bielefeld',
        addressRegion: 'NRW',
        postalCode: '33604',
        addressCountry: 'DE',
      },
      email: 'info@smartsquare.de',
      telephone: '+49 5214481 8690',
    },
  },
  navigationItems: [
    {
      title: 'Softwarelösungen für Maschinenbau',
      titleDesktop: 'Software für Maschinenbau',
      icon: 'fernueberwachung',
      items: [
        {
          title: 'Kundendienst & Aftersales',
          titleFormatted: 'Kundendienst<br />& Aftersales',
          bgColor: 'white',
          textColor: 'dark-blue',
          to: '/softwareloesungen-fuer-maschinenbau/kundendienst-und-aftersales/',
          icon: 'kundendienst-und-aftersales',
        },
        {
          title: 'Prozesse & Daten',
          titleFormatted: 'Prozesse<br />& Daten',
          bgColor: 'green',
          textColor: 'white',
          to: '/softwareloesungen-fuer-maschinenbau/prozesse-und-daten/',
          icon: 'prozesse-und-daten2',
        },
        {
          title: 'Fernüberwachung & Monitoring',
          titleFormatted: 'Fernüberwachung<br />& Monitoring',
          bgColor: 'blue',
          textColor: 'white',
          to: '/softwareloesungen-fuer-maschinenbau/fernueberwachung-und-monitoring/',
          icon: 'fernueberwachung-und-monitoring',
        },
      ],
    },
    {
      title: 'Digitale Geschäftsmodelle',
      to: '/digitale-geschaeftsmodelle/',
      titleFormatted: 'Digitale<br />Geschäftsmodelle',
      bgColor: 'white',
      textColor: 'dark-blue',
      icon: 'digitale-geschaeftsmodelle',
    },
    {
      title: 'Servicebuddy-App',
      to: '/servicebuddy-app/',
      titleFormatted: 'Servicebuddy-<br />App',
      bgColor: 'green',
      textColor: 'white',
      icon: 'servicebuddy-app',
    },
    {
      title: 'Referenz-Projekte',
      to: '/referenz-projekte/',
      titleFormatted: 'Referenz-<br />Projekte',
      bgColor: 'blue',
      textColor: 'white',
      icon: 'referenz-projekte',
    },
    {
      title: 'Team',
      to: '/team/',
      bgColor: 'white',
      textColor: 'dark-blue',
      icon: 'team',
    },
    {
      title: 'Jobs',
      to: '/jobs/',
      bgColor: 'green',
      textColor: 'white',
      icon: 'jobs',
    },
    {
      title: 'Kontakt',
      to: '/kontakt/',
      bgColor: 'blue',
      textColor: 'white',
      icon: 'kontakt',
    },
  ],
  socialMediaItems: [
    {
      title: 'Linkedin',
      to: 'https://de.linkedin.com/company/smartsquare-gmbh',
      icon: 'linkedin',
    },
    {
      title: 'Instagram',
      to: 'https://www.instagram.com/smartsquarehq',
      icon: 'instagram',
    },
    {
      title: 'GitHub',
      to: 'https://github.com/SmartsquareGmbH',
      icon: 'github',
    },
    {
      items: [
        {
          title: 'Spotify',
          to: 'https://spoti.fi/430EGjf',
          icon: 'spotify',
        },
        {
          title: 'Apple Podcasts',
          to: 'https://apple.co/49CegGo',
          icon: 'apple',
        },
        {
          title: 'Amazon Music',
          to: 'https://amzn.to/42IiQR6',
          icon: 'amazon',
        },
        // {
        //   title: 'Google Podcasts',
        //   to: 'https://bit.ly/49CK3aj',
        //   icon: 'google',
        // },
        {
          title: 'Deezer',
          to: 'https://bit.ly/3wfuGGg',
          icon: 'deezer',
        },
      ],
    },
  ],
})
