import revive_payload_client_4sVQNw7RlN from "/home/runner/work/smartsquare-2024/smartsquare-2024/frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/runner/work/smartsquare-2024/smartsquare-2024/frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/runner/work/smartsquare-2024/smartsquare-2024/frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_7pzUtwM1Zj from "/home/runner/work/smartsquare-2024/smartsquare-2024/frontend/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_yVLowv6hDl from "/home/runner/work/smartsquare-2024/smartsquare-2024/frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/runner/work/smartsquare-2024/smartsquare-2024/frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/smartsquare-2024/smartsquare-2024/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/smartsquare-2024/smartsquare-2024/frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import titles_dw2T9lEw4h from "/home/runner/work/smartsquare-2024/smartsquare-2024/frontend/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import defaults_aRl2YLnTcb from "/home/runner/work/smartsquare-2024/smartsquare-2024/frontend/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.js";
import siteConfig_izaWKZ8rEu from "/home/runner/work/smartsquare-2024/smartsquare-2024/frontend/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_Uk9bcXDHeN from "/home/runner/work/smartsquare-2024/smartsquare-2024/frontend/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/smartsquare-2024/smartsquare-2024/frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import seoTitleAndDescription_5vuu3yvtyD from "/home/runner/work/smartsquare-2024/smartsquare-2024/frontend/node_modules/@tricks-gmbh/nuxt-tools/src/layers/base/plugins/seoTitleAndDescription.ts";
import structuredData_CY9Y850MS0 from "/home/runner/work/smartsquare-2024/smartsquare-2024/frontend/node_modules/@tricks-gmbh/nuxt-tools/src/layers/base/plugins/structuredData.ts";
import emitter_eaMgM0oZTb from "/home/runner/work/smartsquare-2024/smartsquare-2024/frontend/plugins/emitter.ts";
import etracker_client_zuKxZcOU5y from "/home/runner/work/smartsquare-2024/smartsquare-2024/frontend/plugins/etracker.client.ts";
import gsap_client_YCLjR5XmPz from "/home/runner/work/smartsquare-2024/smartsquare-2024/frontend/plugins/gsap.client.ts";
import swiper_client_blbPZy93VS from "/home/runner/work/smartsquare-2024/smartsquare-2024/frontend/plugins/swiper.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_7pzUtwM1Zj,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  titles_dw2T9lEw4h,
  defaults_aRl2YLnTcb,
  siteConfig_izaWKZ8rEu,
  inferSeoMetaPlugin_Uk9bcXDHeN,
  chunk_reload_client_UciE0i6zes,
  seoTitleAndDescription_5vuu3yvtyD,
  structuredData_CY9Y850MS0,
  emitter_eaMgM0oZTb,
  etracker_client_zuKxZcOU5y,
  gsap_client_YCLjR5XmPz,
  swiper_client_blbPZy93VS
]