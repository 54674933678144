<template>
  <div class="grid md:mt-vw-12-to-52 md:grid-cols-12">
    <div
      class="mb-vw-4-to-14 mt-vw-11-to-12 flex justify-end md:order-last md:col-span-2 md:my-0"
    >
      <IconComponent :icon="icon" class="size-vw-10-to-20 text-blue" />
    </div>
    <h1 class="text-balance text-vw-headline-level-1 md:col-span-10">
      <slot></slot>
    </h1>
  </div>
</template>

<script lang="ts" setup>
defineProps<{
  icon: string
}>()
</script>
