<template>
  <span
    class="inline-flex items-center gap-vw-2-to-3.5 rounded-full transition-colors"
    :class="{
      'px-vw-4-to-6 py-vw-2-to-3.5 text-vw-button [&_svg]:size-vw-4-to-5':
        !large,
      'px-vw-6-to-9 py-vw-3-to-4.5 text-vw-button-large [&_svg]:size-vw-6-to-7':
        large,
      'bg-white hover:bg-dark-blue hover:text-white': !inverted,
      'bg-dark-blue text-white hover:bg-white hover:text-dark-blue': inverted,
    }"
  >
    <slot></slot>
  </span>
</template>
<script lang="ts" setup>
defineProps<{
  large?: boolean
  inverted?: boolean
}>()
</script>
