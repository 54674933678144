<template>
  <component class="overflow-visible" :is="AsyncComp" />
</template>

<script lang="ts" setup>
// @vite-ignore

const props = defineProps({
  icon: String,
})
const AsyncComp = defineAsyncComponent({
  loader: () =>
    /* @vite-ignore */
    import(`@/assets/icons/${props.icon}.svg?component`),
})
</script>
