
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {
    "buildId": "8546511c-02c1-48a9-a705-d8a3b86a4885"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/home/runner/work/smartsquare-2024/smartsquare-2024/frontend/app.config.ts"
import cfg1 from "/home/runner/work/smartsquare-2024/smartsquare-2024/frontend/node_modules/@tricks-gmbh/nuxt-tools/src/layers/base/app.config.ts"

export default /*@__PURE__*/ defuFn(cfg0, cfg1, inlineConfig)
