import { default as datenschutzPjWS6cELRHMeta } from "/home/runner/work/smartsquare-2024/smartsquare-2024/frontend/pages/datenschutz.vue?macro=true";
import { default as digitale_45geschaeftsmodelleXbF2B1vGMPMeta } from "/home/runner/work/smartsquare-2024/smartsquare-2024/frontend/pages/digitale-geschaeftsmodelle.vue?macro=true";
import { default as impressumP5snUszsUPMeta } from "/home/runner/work/smartsquare-2024/smartsquare-2024/frontend/pages/impressum.vue?macro=true";
import { default as indexmk8dOmPBzaMeta } from "/home/runner/work/smartsquare-2024/smartsquare-2024/frontend/pages/index.vue?macro=true";
import { default as _91_46_46_46slug_93w3wCTij2FIMeta } from "/home/runner/work/smartsquare-2024/smartsquare-2024/frontend/pages/jobs/[...slug].vue?macro=true";
import { default as indexlbDRCJupyPMeta } from "/home/runner/work/smartsquare-2024/smartsquare-2024/frontend/pages/jobs/index.vue?macro=true";
import { default as kontaktNmDvNdU0GsMeta } from "/home/runner/work/smartsquare-2024/smartsquare-2024/frontend/pages/kontakt.vue?macro=true";
import { default as referenz_45projekteTLLCJUYeHzMeta } from "/home/runner/work/smartsquare-2024/smartsquare-2024/frontend/pages/referenz-projekte.vue?macro=true";
import { default as servicebuddy_45appDTyLopqpwRMeta } from "/home/runner/work/smartsquare-2024/smartsquare-2024/frontend/pages/servicebuddy-app.vue?macro=true";
import { default as fernueberwachung_45und_45monitoringc1HNAANc4XMeta } from "/home/runner/work/smartsquare-2024/smartsquare-2024/frontend/pages/softwareloesungen-fuer-maschinenbau/fernueberwachung-und-monitoring.vue?macro=true";
import { default as indexDZeaRrOSVvMeta } from "/home/runner/work/smartsquare-2024/smartsquare-2024/frontend/pages/softwareloesungen-fuer-maschinenbau/index.vue?macro=true";
import { default as kundendienst_45und_45aftersalesTMoKffRdXlMeta } from "/home/runner/work/smartsquare-2024/smartsquare-2024/frontend/pages/softwareloesungen-fuer-maschinenbau/kundendienst-und-aftersales.vue?macro=true";
import { default as prozesse_45und_45daten0qzNbLBsAdMeta } from "/home/runner/work/smartsquare-2024/smartsquare-2024/frontend/pages/softwareloesungen-fuer-maschinenbau/prozesse-und-daten.vue?macro=true";
import { default as teamiXCIOHGwk2Meta } from "/home/runner/work/smartsquare-2024/smartsquare-2024/frontend/pages/team.vue?macro=true";
export default [
  {
    name: datenschutzPjWS6cELRHMeta?.name ?? "datenschutz",
    path: datenschutzPjWS6cELRHMeta?.path ?? "/datenschutz/",
    meta: datenschutzPjWS6cELRHMeta || {},
    alias: datenschutzPjWS6cELRHMeta?.alias || [],
    redirect: datenschutzPjWS6cELRHMeta?.redirect,
    component: () => import("/home/runner/work/smartsquare-2024/smartsquare-2024/frontend/pages/datenschutz.vue").then(m => m.default || m)
  },
  {
    name: digitale_45geschaeftsmodelleXbF2B1vGMPMeta?.name ?? "digitale-geschaeftsmodelle",
    path: digitale_45geschaeftsmodelleXbF2B1vGMPMeta?.path ?? "/digitale-geschaeftsmodelle/",
    meta: digitale_45geschaeftsmodelleXbF2B1vGMPMeta || {},
    alias: digitale_45geschaeftsmodelleXbF2B1vGMPMeta?.alias || [],
    redirect: digitale_45geschaeftsmodelleXbF2B1vGMPMeta?.redirect,
    component: () => import("/home/runner/work/smartsquare-2024/smartsquare-2024/frontend/pages/digitale-geschaeftsmodelle.vue").then(m => m.default || m)
  },
  {
    name: impressumP5snUszsUPMeta?.name ?? "impressum",
    path: impressumP5snUszsUPMeta?.path ?? "/impressum/",
    meta: impressumP5snUszsUPMeta || {},
    alias: impressumP5snUszsUPMeta?.alias || [],
    redirect: impressumP5snUszsUPMeta?.redirect,
    component: () => import("/home/runner/work/smartsquare-2024/smartsquare-2024/frontend/pages/impressum.vue").then(m => m.default || m)
  },
  {
    name: indexmk8dOmPBzaMeta?.name ?? "index",
    path: indexmk8dOmPBzaMeta?.path ?? "/",
    meta: indexmk8dOmPBzaMeta || {},
    alias: indexmk8dOmPBzaMeta?.alias || [],
    redirect: indexmk8dOmPBzaMeta?.redirect,
    component: () => import("/home/runner/work/smartsquare-2024/smartsquare-2024/frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93w3wCTij2FIMeta?.name ?? "jobs-slug",
    path: _91_46_46_46slug_93w3wCTij2FIMeta?.path ?? "/jobs/:slug(.*)*",
    meta: _91_46_46_46slug_93w3wCTij2FIMeta || {},
    alias: _91_46_46_46slug_93w3wCTij2FIMeta?.alias || [],
    redirect: _91_46_46_46slug_93w3wCTij2FIMeta?.redirect,
    component: () => import("/home/runner/work/smartsquare-2024/smartsquare-2024/frontend/pages/jobs/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexlbDRCJupyPMeta?.name ?? "jobs",
    path: indexlbDRCJupyPMeta?.path ?? "/jobs/",
    meta: indexlbDRCJupyPMeta || {},
    alias: indexlbDRCJupyPMeta?.alias || [],
    redirect: indexlbDRCJupyPMeta?.redirect,
    component: () => import("/home/runner/work/smartsquare-2024/smartsquare-2024/frontend/pages/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: kontaktNmDvNdU0GsMeta?.name ?? "kontakt",
    path: kontaktNmDvNdU0GsMeta?.path ?? "/kontakt/",
    meta: kontaktNmDvNdU0GsMeta || {},
    alias: kontaktNmDvNdU0GsMeta?.alias || [],
    redirect: kontaktNmDvNdU0GsMeta?.redirect,
    component: () => import("/home/runner/work/smartsquare-2024/smartsquare-2024/frontend/pages/kontakt.vue").then(m => m.default || m)
  },
  {
    name: referenz_45projekteTLLCJUYeHzMeta?.name ?? "referenz-projekte",
    path: referenz_45projekteTLLCJUYeHzMeta?.path ?? "/referenz-projekte/",
    meta: referenz_45projekteTLLCJUYeHzMeta || {},
    alias: referenz_45projekteTLLCJUYeHzMeta?.alias || [],
    redirect: referenz_45projekteTLLCJUYeHzMeta?.redirect,
    component: () => import("/home/runner/work/smartsquare-2024/smartsquare-2024/frontend/pages/referenz-projekte.vue").then(m => m.default || m)
  },
  {
    name: servicebuddy_45appDTyLopqpwRMeta?.name ?? "servicebuddy-app",
    path: servicebuddy_45appDTyLopqpwRMeta?.path ?? "/servicebuddy-app/",
    meta: servicebuddy_45appDTyLopqpwRMeta || {},
    alias: servicebuddy_45appDTyLopqpwRMeta?.alias || [],
    redirect: servicebuddy_45appDTyLopqpwRMeta?.redirect,
    component: () => import("/home/runner/work/smartsquare-2024/smartsquare-2024/frontend/pages/servicebuddy-app.vue").then(m => m.default || m)
  },
  {
    name: fernueberwachung_45und_45monitoringc1HNAANc4XMeta?.name ?? "softwareloesungen-fuer-maschinenbau-fernueberwachung-und-monitoring",
    path: fernueberwachung_45und_45monitoringc1HNAANc4XMeta?.path ?? "/softwareloesungen-fuer-maschinenbau/fernueberwachung-und-monitoring/",
    meta: fernueberwachung_45und_45monitoringc1HNAANc4XMeta || {},
    alias: fernueberwachung_45und_45monitoringc1HNAANc4XMeta?.alias || [],
    redirect: fernueberwachung_45und_45monitoringc1HNAANc4XMeta?.redirect,
    component: () => import("/home/runner/work/smartsquare-2024/smartsquare-2024/frontend/pages/softwareloesungen-fuer-maschinenbau/fernueberwachung-und-monitoring.vue").then(m => m.default || m)
  },
  {
    name: indexDZeaRrOSVvMeta?.name ?? "softwareloesungen-fuer-maschinenbau",
    path: indexDZeaRrOSVvMeta?.path ?? "/softwareloesungen-fuer-maschinenbau/",
    meta: indexDZeaRrOSVvMeta || {},
    alias: indexDZeaRrOSVvMeta?.alias || [],
    redirect: indexDZeaRrOSVvMeta?.redirect,
    component: () => import("/home/runner/work/smartsquare-2024/smartsquare-2024/frontend/pages/softwareloesungen-fuer-maschinenbau/index.vue").then(m => m.default || m)
  },
  {
    name: kundendienst_45und_45aftersalesTMoKffRdXlMeta?.name ?? "softwareloesungen-fuer-maschinenbau-kundendienst-und-aftersales",
    path: kundendienst_45und_45aftersalesTMoKffRdXlMeta?.path ?? "/softwareloesungen-fuer-maschinenbau/kundendienst-und-aftersales/",
    meta: kundendienst_45und_45aftersalesTMoKffRdXlMeta || {},
    alias: kundendienst_45und_45aftersalesTMoKffRdXlMeta?.alias || [],
    redirect: kundendienst_45und_45aftersalesTMoKffRdXlMeta?.redirect,
    component: () => import("/home/runner/work/smartsquare-2024/smartsquare-2024/frontend/pages/softwareloesungen-fuer-maschinenbau/kundendienst-und-aftersales.vue").then(m => m.default || m)
  },
  {
    name: prozesse_45und_45daten0qzNbLBsAdMeta?.name ?? "softwareloesungen-fuer-maschinenbau-prozesse-und-daten",
    path: prozesse_45und_45daten0qzNbLBsAdMeta?.path ?? "/softwareloesungen-fuer-maschinenbau/prozesse-und-daten/",
    meta: prozesse_45und_45daten0qzNbLBsAdMeta || {},
    alias: prozesse_45und_45daten0qzNbLBsAdMeta?.alias || [],
    redirect: prozesse_45und_45daten0qzNbLBsAdMeta?.redirect,
    component: () => import("/home/runner/work/smartsquare-2024/smartsquare-2024/frontend/pages/softwareloesungen-fuer-maschinenbau/prozesse-und-daten.vue").then(m => m.default || m)
  },
  {
    name: teamiXCIOHGwk2Meta?.name ?? "team",
    path: teamiXCIOHGwk2Meta?.path ?? "/team/",
    meta: teamiXCIOHGwk2Meta || {},
    alias: teamiXCIOHGwk2Meta?.alias || [],
    redirect: teamiXCIOHGwk2Meta?.redirect,
    component: () => import("/home/runner/work/smartsquare-2024/smartsquare-2024/frontend/pages/team.vue").then(m => m.default || m)
  }
]