<template>
  <div>
    <NuxtLayout name="default">
      <div class="pb-vw-24-to-60">
        <div
          class="bg-software-und-maschinenbau-intro bg-contain bg-[right_-50vw_center] bg-no-repeat 3xl:bg-[right_calc((50vw-1523px)/2)_center]"
        >
          <div class="container">
            <PageHeader icon="rocket-down">
              <em>404</em><br />
              Das hat nicht so gezündet, wie vorgesehen. Die gesuchte Seite
              wurde nicht gefunden.</PageHeader
            >
            <div class="mt-vw-4-to-8 flex gap-vw-2-to-4">
              <NuxtLink
                class="rounded-full focus:outline-none focus-visible:outline-current"
                to="/"
                ><AppButton
                  >Zurück zur Startseite<IconComponent
                    icon="circle-arrow-right-filled" /></AppButton
              ></NuxtLink>
            </div>
            <div class="relative">
              <NuxtImgWrapper
                src="/img/404/404.png"
                alt=""
                width="1360"
                height="790"
                disable-lazy-loading
                format="webp"
                class="mt-vw-11-to-20 w-full rounded"
                preload
              />
              <AppOverlay></AppOverlay>
            </div>
          </div>
        </div>
      </div>
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
import type { NuxtError } from 'nuxt/app'

const props = defineProps<{
  error: NuxtError
}>()

if (props.error) {
  console.error(props.error.message)
}

useHead({
  title: 'Fehler 404',
  meta: [{ name: 'robots', content: 'noindex' }],
  // ----------------------------------------------------------------------------------------------------------- //
  // don't remove the following two data attributes, essential for testing
  htmlAttrs: {
    'data-error-status-code': props.error.statusCode,
    'data-error-message': props.error.message,
  },
  // ----------------------------------------------------------------------------------------------------------- //
})
</script>
