export default defineNuxtPlugin((nuxt) => {
  useHead({
    script: [
      {
        id: '_etLoader',
        src: `//code.etracker.com/code/e.js`,
        'data-block-cookies': 'true',
        'data-secure-code': nuxt.$config.public.etrackerId,
        async: true,
      },
    ],
  })
})
